function Vconstruct() {

    return (
        <>
            <p>
                vConstruct has provided me with a platform to grow and find my interests in various fields. I have played multiple roles in multiple domains like  <abbr title="Building Information Modeling">BIM</abbr>, AI, Data Analytics, Software Development. Below is a list of few of my roles and responsibilities: 
            </p>
            <ul>
                <li>Leading a newly formed R&D team with the goal of revolutionizing the construction industry with the use of technology</li>
                <li>Actively involved in the product development of a <a href="https://en.wikipedia.org/wiki/Digital_twin">Digital Twin</a> platform for the organization.</li>
                <li>Involved in the integration of various VDC Services and collaborating with our software development
                    team to build solutions & products to optimize our existing workflows.</li>
                <li>Optimized existing workflows by creating scripts to automate repetitive steps in the process and help improve the productivity of the entire team</li>
                <li>Generated Power BI Dashboards for various Virtual Design and Construction (VDC) Services from different sources (Web, API...) that provide insights to understand the project</li>
                <li>Trained and helped the team in creating Dynamo Scripts to provide optimized BIM workflows</li>
                <li>Innovative solution to simplify resource planning using Excel which was later expanded and used by the whole company</li>
                <li>Created a full-fledged solution for a <abbr title="Enterprise Resource Planning">ERP</abbr> & <abbr title="Customer Relationship Management">CRM</abbr> tool with multiple modules (Sales & Projects, Invoicing, Resource Planning etc..) for the company using Google Sheets</li>
                <li>Created Power BI Dashboards using ERP & CRM data to help taking informed business decisions</li>
            </ul>
        </>

    );
}

export default Vconstruct;