import React, { useEffect } from 'react';
import './logo.css';
import './shadowAnimate.css';
import './neon.css';

import { usePageVisibility } from "../../../utils/pageVisibility";
import animateShadow from './shadowAnimate';

export default function Logo(props) {
    const isVisible = usePageVisibility();
    function startAnimation() {
        var wrapper = document.querySelector('svg#logo');
        wrapper.classList.add('active');
    }

    useEffect(() => {
        // // Check if browser tab is in focus and start Logo Animation (Reference: https://blog.sethcorker.com/harnessing-the-page-visibility-api-with-react)
        if (isVisible) {
            startAnimation();
        }

        // animateShadow("logo")
        animateShadow("logo", "home")
    });
    return (
        <svg id={props.id} className={`inner-element ${props.class}`} viewBox="0 0 300 300" version="1.1" xmlns="http://www.w3.org/2000/svg" width="300" height="300" strokeWidth="8" strokeMiterlimit="8">
            <title>Logo of Anwesh Gangula</title>
            {/* Created using Powerpoint and https://yqnn.github.io/svg-path-editor */}
            <g className="logoPaths" transform="translate(150 150) scale(0.9, 0.9)">
                <path className="fillPath" fill="#0082E6" stroke="#0082E6" d="M -23 -137 C 1 -121 18 -92 18 -61 V 64 C 18 80 30 94 44 103 C 58 112 75 112 90 103 S 114 75 114 57 V 34 H 47 V -2 H 150 V 58 C 150 93 133 122 108 138 C 83 154 50 154 25 138 C 1 122 -17 94 -17 57 V -55 C -17 -74 -26 -92 -41 -102 C -56 -112 -74 -112 -89 -102 C -104 -91 -114 -74 -114 -55 H -150 C -150 -89 -135 -119 -107 -138 C -84 -154 -49 -154 -23 -137 Z" />
                <path fill="none" stroke="#cccccc33" className="strokePath" d="M -89 102 C -74 113 -56 112 -41 102 C -35 99 -33 97 -30 93 C -25 109 -18 119 -12 129 C -16 134 -19 136 -22 138 C -48 154 -81 154 -107 138 C -132 123 -150 94 -150 58 V -2 H -47 V 34 H -114 V 57 C -114 75 -104 93 -89 102 Z M 109 -137 C 136 -119 150 -89 150 -55 H 114 C 114 -74 106 -91 90 -101 C 76 -111 58 -110 44 -101 C 40 -98 37 -96 31 -89 C 24 -107 19 -116 11 -125 C 19 -133 22 -134 25 -136 C 47 -154 83 -154 109 -137 Z" />
            </g>
        </svg>

    );
}
