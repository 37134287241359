import './about.css';
import AnweshAvif from '../../Assets/Anwesh-Gangula.avif';
import AnweshWebp from '../../Assets/Anwesh-Gangula.webp';
import AnweshPng from '../../Assets/Anwesh-Gangula.png';

export default function About() {

    //Reference: https://stackoverflow.com/a/7091965/6908282
    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    return (
        <section id="about-me" className="center page">
            <h1>About Me</h1>
            <p>Data, Automation and Technology</p>
            <div className="title-shadow"></div>
            <div className="my-summary">
                <div>
                    <picture>
                        <source type="image/avif" srcSet={AnweshAvif} />
                        <source type="image/webp" srcSet={AnweshWebp} />
                        <img id="photo" src={AnweshPng} width="150" height="150" alt="A photograph of Anwesh Gangula" />
                    </picture>
                    <div className="circle-shadow"></div>
                </div>
                <p style={{ "textAlign": "justify" }}>
                    {/* TODO - Calculate experience years automatically */}
                    Anwesh Gangula is a guy with wide range of interests, most of which involve Technology and Product Management. He is a self-taught programmer and software developer with experience in BIM, Computational Design, Web Development and more. He strongly believes that there is a gap in Technology and the 3D industry and is working towards bridging the gap. He is also knowledgable in Data Analytics & Visualization and is actively learning 3D Web Development technologies. He is good at understanding complex problems and transforming them into logical steps towards building a solution.
                    <br /><br />
                    Being an architecture graduate, he is inclined towards design and user experience too, always approaching things from an end-user perspective and figuring out efficient ways to solve any problem. In his spare time, he browses through latest trends in Digital Reality, UI/UX, AI/ML or enjoys watching a movie.
                    <br /><br />
                    <b>Three Words about me:</b><br />
                    <a href="https://en.wikipedia.org/wiki/Technophilia">Technophilic</a>, Patient, Innovative
                </p>
            </div>
            <div className="distribute" id="details">
                <div>
                    <p><b>Name</b></p>
                    <p>Anwesh Gangula</p>
                </div>
                <div>
                    <p><b>Age</b></p>
                    <p>{getAge("1994/12/08")}</p>
                </div>
                <div>
                    <p><b>Contact</b></p>
                    <a href="mailto:gangulaanwesh@gmail.com">gangulaanwesh@gmail.com</a>
                </div>
                {/* <div>
                    <p><b>Phone</b></p>
                    <a href="tel:+919800121777">gangulaanwesh@gmail.com</a>
                </div> */}
                <div>
                    <p><b>Location</b></p>
                    <p>Pune, India</p>
                </div>
            </div>
            <h2>Languages</h2>
            <div className="distribute" id="Languages">
                <div>
                    <p><b>Telugu</b></p>
                    <div className="rating-box">
                        <div className="rating" style={{ "width": "100%" }}></div>
                    </div>
                    <p>Mother Tongue</p>
                </div>
                <div>
                    <p><b>English</b></p>
                    <div className="rating-box">
                        <div className="rating" style={{ "width": "80%" }}></div>
                    </div>
                </div>
                <div>
                    <p><b>Hindi</b></p>
                    <div className="rating-box">
                        <div className="rating" style={{ "width": "80%" }}></div>
                    </div>
                </div>
            </div>
        </section>
    );
}
