import './projects.css';
import AnweshGangula_JPEG from './Images/anweshgangula.jpeg';
import Basic_Forge_JPEG from './Images/Basic_Forge.jpeg';
import City_of_Code_GitHub_JPEG from './Images/City_of_Code_GitHub.jpeg';
import Covid_19_India_JPEG from './Images/Covid_19_India.jpeg';
import Steering_Behaviour_JPEG from './Images/Steering_Behaviour.jpeg';
import Threejs_101_JPEG from './Images/Threejs_101.jpeg';

import AnweshGangula_AVIF from './Images/anweshgangula.avif';
import Basic_Forge_AVIF from './Images/Basic_Forge.avif';
import City_of_Code_GitHub_AVIF from './Images/City_of_Code_GitHub.avif';
import Covid_19_India_AVIF from './Images/Covid_19_India.avif';
import Steering_Behaviour_AVIF from './Images/Steering_Behaviour.avif';
import Threejs_101_AVIF from './Images/Threejs_101.avif';

import AnweshGangula_WebP from './Images/anweshgangula.webp';
import Basic_Forge_WebP from './Images/Basic_Forge.jpeg';
import City_of_Code_GitHub_WebP from './Images/City_of_Code_GitHub.webp';
import Covid_19_India_WebP from './Images/Covid_19_India.webp';
import Steering_Behaviour_WebP from './Images/Steering_Behaviour.webp';
import Threejs_101_WebP from './Images/Threejs_101.webp';

// Reference: https://reactjs.org/docs/lists-and-keys.html#keys-must-only-be-unique-among-siblings
export default function Projects() {
    let projectsList = [
        {
            ImageJPEG: AnweshGangula_JPEG,
            ImageAVIF: AnweshGangula_AVIF,
            ImageWebP: AnweshGangula_WebP,
            URL: "/",
            name: "anweshgangula.com",
            // sourceCodeURL: "https://github.com/AnweshGangula/My-Website",
            // sourceCodeName: "GitHub: My-Website",
            tags: ["HTML", "CSS", "React.js"]
        },
        {
            ImageJPEG: City_of_Code_GitHub_JPEG,
            ImageAVIF: City_of_Code_GitHub_AVIF,
            ImageWebP: City_of_Code_GitHub_WebP,
            URL: "https://anweshgangula.github.io/City-of-Code-GitHub/dist/",
            name: "City of Code: GitHub",
            sourceCodeURL: "https://github.com/AnweshGangula/City-of-Code-GitHub",
            sourceCodeName: "City of Code: GitHub",
            tags: ["Three.js", "GraphQL"]
        },
        {
            ImageJPEG: Covid_19_India_JPEG,
            ImageAVIF: Covid_19_India_AVIF,
            ImageWebP: Covid_19_India_WebP,
            URL: "https://anweshgangula.github.io/Covid19-India",
            name: "Covid-19 India",
            sourceCodeURL: "https://github.com/AnweshGangula/Covid19-India",
            sourceCodeName: "GitHub: Covid-19 India",
            tags: ["D3.js", "Python", "API"]
        },
        {
            ImageJPEG: Steering_Behaviour_JPEG,
            ImageAVIF: Steering_Behaviour_AVIF,
            ImageWebP: Steering_Behaviour_WebP,
            URL: "https://anweshgangula.github.io/Steering-Behaviour",
            name: "Creative Coding",
            sourceCodeURL: "https://github.com/AnweshGangula/Steering-Behaviour",
            sourceCodeName: "GitHub: Steering Behaviours",
            tags: ["p5.js"]
        },
        {
            ImageJPEG: Threejs_101_JPEG,
            ImageAVIF: Threejs_101_AVIF,
            ImageWebP: Threejs_101_WebP,
            URL: "https://anweshgangula.github.io/threejs-101",
            name: "Threejs 101",
            sourceCodeURL: "https://github.com/AnweshGangula/threejs-101",
            sourceCodeName: "GitHub: Threejs 101",
            tags: ["Three.js"]
        },
        {
            ImageJPEG: Basic_Forge_JPEG,
            ImageAVIF: Basic_Forge_AVIF,
            ImageWebP: Basic_Forge_WebP,
            URL: "https://anweshgangula.github.io/Basic-Forge/",
            name: "Basic Forge",
            sourceCodeURL: "https://github.com/AnweshGangula/Basic-Forge",
            sourceCodeName: "GitHub: Basic Forge",
            tags: ["Autodesk Forge"]
        }
    ]

    function ProjectsHTML(props) {
        // Correct usage of Lists & Keys: https://reactjs.org/docs/lists-and-keys.html#extracting-components-with-keys
        const project = props.project
        return (
            <li className="project">

                <a href={project.URL} className="siteURL">
                    <picture>
                        {project.ImageAVIF ?
                            (
                                <source type="image/avif" srcSet={project.ImageAVIF} />
                            ) : (null)}
                        {project.ImageWebP ?
                            (
                                <source type="image/webp" srcSet={project.ImageWebP} />
                            ) : (null)}
                        {project.ImageJPEG ?
                            (
                                <img className="projectImage" src={project.ImageJPEG} alt={`A screenshot of the project - ${project.name}`} width="150" height="150" />
                            ) : (null)}
                    </picture>
                    <p>{project.name}</p>
                </a>
                {/* Check if sourceCodeURL exists in the projectsList array- reference: https://stackoverflow.com/a/64601059/6908282 */}
                {project.sourceCodeURL ?
                    (<p className="sourceCode" >Source:&nbsp;
                        <a href={project.sourceCodeURL}>
                            {project.sourceCodeName}
                        </a>
                    </p>)
                    :
                    (null)
                }

                <div className="project_tags">
                    <p>Tags: </p>
                    <div className="Skill-Categories distribute">
                        {
                            project.tags.map((tag, index) =>
                                <p key={index}>
                                    {tag}
                                </p>
                            )
                        }
                    </div>

                </div>
            </li>
        )
    }

    const projects = projectsList.map((project, index) =>
        <ProjectsHTML key={index} project={project} />
    )
    return (
        <section id="Projects" className="page">
            <h1>Project Highlights</h1>
            <p style={{ "fontSize": "small" }}>
                <strong>Note: </strong>
                Below is a selected list of projects. You can find more of my personal projects (<em>completed and active</em> ) in my <a href="https://github.com/AnweshGangula?tab=repositories&type=source&sort=stargazers">GitHub Profile</a>
            </p>
            <div className="title-shadow"></div>
            <ul className="projectList">
                {projects}
            </ul>
        </section>
    );
}
