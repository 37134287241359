import './themeToggle.css';
import { useEffect, useState } from 'react';


export default function ThemeToggle() {
    const [isDark, setDark] = useState("false"); // this is only used to change the radius of circle SVG element

    const buttonLabel = isDark ? "Light Mode" : "Dark Mode";
    const radius = isDark ? 8 : 5

    useEffect(() => {
        (function () {
            let preferLight = localStorage.getItem('theme') === 'theme-light'
            changeTheme(preferLight ? 'theme-light' : 'theme-dark');
            setDark(!preferLight); 
        })();
    });

    // function to set a given theme/color-scheme
    function changeTheme(themeName) {
        const themeColor = getComputedStyle(document.body).getPropertyValue('--background-color').trim();
        localStorage.setItem('theme', themeName);
        document.body.className = themeName;

        document.querySelector('meta[name="theme-color"]').setAttribute('content', themeColor);
    }

    // function to toggle between light and dark theme
    function handleClick(e) {
        setDark(!isDark);

        let isDarkMode = localStorage.getItem('theme') === 'theme-dark';
        let theme = isDarkMode ? 'theme-light' : 'theme-dark'
        changeTheme(theme);

        let themeDiv = document.getElementById("themeBack")
        themeDiv.className = theme;
        setTimeout(() => { themeDiv.className = "" }, 400);
    }

    return (
        // this is referenced from https://www.joshwcomeau.com - yet to be animated accordingly
        // https://svgartista.net/ can help in animating this even better - simply copy paste the SVG
        <button id="themeToggle" onClick={handleClick} title={`Activate ${buttonLabel}`} aria-label={`Activate ${buttonLabel}`}>
            <svg width="20" height="20" viewBox="0 0 20 20" alt="Toggle button to switch between Dark and Light theme">
                <mask id="moon-mask">
                    <rect x="0" y="0" width="20" height="20" fill="#FFF"></rect>
                    <circle cx="16" cy="10" r="8" fill="black"></circle>
                </mask>
                <circle cx="10" cy="10" fill="var(--dark-text-clr)" mask="url(#moon-mask)" r={radius} ></circle>
                <g fill="var(--dark-text-clr)" className="sunrays">
                    {/* TODO: replace circles with lines from "../Assets/Sun.svg" */}
                    <circle cx="18" cy="10" r="1.5" ></circle>
                    <circle cx="14" cy="18" r="1.5" ></circle>
                    <circle cx="6" cy="18" r="1.5" ></circle>
                    <circle cx="2" cy="10" r="1.5" ></circle>
                    <circle cx="6" cy="2" r="1.5" ></circle>
                    <circle cx="14" cy="2" r="1.5" ></circle>
                </g>
            </svg>
        </button>
    );

}