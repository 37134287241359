import { intervalToDuration, formatDuration } from 'date-fns'

import './experience.css';

import Vconstruct from './Companies/vConstruct'
import NuDe from './Companies/Nu. De'

export default function Experience() {

    let companies = [
        {
            name: "vConstruct",
            expSummary: <Vconstruct />,
            roles: [
                // Use Fragment to store a JSX variable: https://stackoverflow.com/a/69803036/6908282 
                // https://stackoverflow.com/a/53281251/6908282
                { title: <>Sr. Software Engineer - <abbr title="Building Information Modeling">BIM</abbr></>, start: "June 01, 2021", end: "Present" },
                { title: <>Sr. Project Engineer - <abbr title="Virtual Design &amp; Construction">VDC</abbr></>, start: "Apr 01, 2020", end: "May 31, 2021" },
                { title: "VDC Engineer", start: "July 25, 2017", end: "Mar 31, 2020" }
            ],
            location: "Pune, India",
            websiteURL: "https://vConstruct.com",
            websiteName: "vConstruct.com"
        },
        {
            name: "Nu. De",
            expSummary: <NuDe />,
            roles: [
                { title: "Architectural Internship", start: "Jan 10, 2016", end: "Jul 22, 2016" }
            ],
            location: "Bangalore, India",
            websiteURL: "http://www.nudeoffices.com",
            websiteName: "NuDeOffices.com"
        },
    ]

    const options = {
        // Format Date in JS: https://stackoverflow.com/a/69883358/6908282
        year: 'numeric',
        month: 'short',
    }

    let skillHTML = companies.map((company, index1) => {
        let startDateList = [];
        let endDateList = [];
        const roles = company.roles.map((role, index2) => {
            const start = new Date(role.start);
            startDateList.push(start);
            const end = role.end === "Present" ? new Date() : new Date(role.end)
            endDateList.push(end);

            const dateRange = `${start.toLocaleString(undefined, options)} - ${role.end === "Present" ? "Present" : end.toLocaleString(undefined, options)}`

            return (
                <div key={`${index1}-${index2}`}>
                    {/* dangerouslySetInnerHTML to render HTML Tags stored in variales - VDC abbr is stored in above var:
            https://stackoverflow.com/a/51558002/6908282 */}
                    {/* <p><b dangerouslySetInnerHTML={{ __html: role.title }} /></p> */}
                    <p><b>{role.title}</b></p>
                    <p>{dateRange}</p>
                </div>)
        }
        )
        const minStartDate = new Date(Math.min(...startDateList));
        const maxEndDate = new Date(Math.max(...endDateList));

        const totalDuration = intervalToDuration({
            start: minStartDate,
            end: maxEndDate
        })
        const textDuration = formatDuration(totalDuration, { format: ['years', 'months'], delimiter: ', ' })

        // console.log({ totalYears, remMonths, textDuration })

        return (
            < div id={company.websiteName.replace(".com", "")} key={index1} >
                <div className="company-roles">
                    <h3>{company.name}</h3>
                    <p>{textDuration}</p>
                    {roles}
                </div>
                <div>
                    {company.expSummary}
                    <div className="distribute company_info">
                        <p><b>{company.location}</b></p>
                        <p><b><a href={company.websiteURL} target="_blank" rel="noreferrer">{company.websiteURL}</a></b></p>
                    </div>
                </div>
            </div >
        )
    })

    return (
        <section id="Experience" className="page">
            <h1>Experience</h1>
            <div className="title-shadow"></div>
            <div id="career-details">
                {skillHTML}
            </div>
        </section>
    );
}
