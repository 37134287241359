import './home.css';
import Logo from './Logo/logo'

import Tilt from 'react-parallax-tilt';

function Home() {
    return (
        <section className="center page" id="home">
            <div>
                <Tilt className="parallax-effect" perspective={500} trackOnWindow={true} tiltReverse={true} reset={false} tiltAngleXInitial={-20} tiltAngleYInitial={0} glareEnable={true} glareMaxOpacity={0.05} glarePosition="all">

                    {/* TODO: on click of the logo animate a 2D character to wave hand from behind the Tilt div - like this: https://twitter.com/jh3yy/status/1295782167977615361*/}
                    {/* React-Parallax-Tile - https://github.com/mkosir/react-parallax-tilt */}
                    <Logo id="logo" />
                </Tilt>
                <h1>Anwesh Gangula</h1>

                {/* Quotes Embed from Goodreads.com  - https://www.goodreads.com/quotes/list/126395880 */}
                {/* TODO: Lighthouse Large Contentful Paint - use React-lazy to lazy-load quotes (https://web.dev/code-splitting-suspense)
                          use React Suspense to load quotes frfom Goodreads: https://reactjs.org/docs/concurrent-mode-suspense.html */}
                <div>
                    <blockquote cite="https://www.goodreads.com/quotes/list/126395880" id="gr_quote_body"></blockquote>
                    <div style={{ "textAlign": "right" }}><a href="https://www.goodreads.com/quotes/list/126395880" style={{ "color": "var(--light-text-clr)", "textDecoration": "none", "fontSize": "10px" }} rel="nofollow">Goodreads Quotes</a></div>
                </div>
            </div>

            {/* <p>Scroll Down</p> */}
            <a href="#about-me" title="Next Section" id="arrow-down">Scroll Down</a>
            <a href="#home" title="Go to Top" id="arrow-up">Back to Top</a>
        </section>
    );
}

export default Home;