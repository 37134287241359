import './skills.css'

import Tools from './Tools/Tools';
import SkillLegend from './Legend/legend'

function Skills() {
    return (
        <section id="Skills" className="center page">
            <SkillLegend />
            <Tools />
        </section>
    );
}

export default Skills;
