import './Tools.css';

// Reference: https://reactjs.org/docs/lists-and-keys.html#keys-must-only-be-unique-among-siblings
export default function Tools() {
  let skillsList = [
    { name: "JavaScript", value: 60, rating: "Intermediate+" },
    { name: "Three.js", value: 40, rating: "Intermediate" },
    { name: "Python", value: 70, rating: "Intermediate+" },
    { name: "C#", value: 40, rating: "Intermediate" },
    // Use Fragment to store a JSX variable: https://stackoverflow.com/a/69803036/6908282 
    // https://stackoverflow.com/a/53281251/6908282
    { name: <>Excel <abbr title="Visual Basic for Applications">VBA</abbr></>, value: 75, rating: "Skilled" },
    { name: "MS Power Platform", value: 75, rating: "Skilled" },
    { name: "Microsoft Power BI", value: 85, rating: "Skilled+" },
    { name: "Autodesk Revit", value: 85, rating: "Skilled+" },
    { name: "Microsoft Excel", value: 95, rating: "Advanced" },
    { name: "Autodesk Dynamo", value: 75, rating: "Skilled" },
    { name: "Autodesk Forge", value: 45, rating: "Beginner+" },
    { name: "Autodesk Navisworks", value: 85, rating: "Skilled+" },
    // { name: "Adobe XD", value: 66, rating: "Intermediate" },
    { name: "Microsoft Power Automate", value: 80, rating: "Skilled+" },
    // { name: "Web Design", value: 70, rating: "Intermediate+" },
  ]

  function ToolCard(props) {
    // Correct usage of Lists & Keys: https://reactjs.org/docs/lists-and-keys.html#extracting-components-with-keys
    const skill = props.skill
    return (
      <div className="toolCard">
        <p>
          {skill.name}
        </p>
        <progress title={skill.rating} value={skill.value} max="100"></progress>
        <small>{skill.rating}</small>
        <div className="toolCardBackground"></div>
      </div>
    )
  }

  let skillHTML = skillsList.map((skill, index) =>
    <ToolCard key={index} skill={skill} />
  )
  return (
    // <></> is shorthand for <React.Fragment></React.Fragment> : https://stackoverflow.com/a/69803036/6908282
    <>
      <h2>Tools</h2>
      <div id="tools">
        {skillHTML}
      </div>

      <div id="Others">
        <h4>Other Skills:</h4>
        <div className="Skill-Categories distribute">
          <p>Three.js</p>
          <p>Autodesk BIM 360</p>
          <p>SQL</p>
          <p>Power BI <abbr title="Data Analysis Expressions">DAX</abbr></p>
          <p>Microsoft Access</p>
          <p>Grasshopper - Rhino 3D</p>
        </div>
      </div>

      <br />

      <div id="Interests">
        <h3 style={{ "textAlign": "left" }}>Interests</h3>
        <div className="Skill-Categories distribute">
          <p><abbr title="Visual Effects">VFX</abbr></p>
          <p>Motion Graphics</p>
          <p>Game Development</p>
          <p>Data Science</p>
          <p>Machine Learning</p>
          <p>Creative Coding</p>
          <p>Digital Reality</p>
        </div>
        <div className="Skill-Categories distribute">
          <p>React.js</p>
          <p>Blender</p>
          <p>Adobe After Effects</p>
          <p>Houdini</p>
          <p>Adobe Photoshop</p>
          <p>Autodesk 3DS MAX</p>
          <p>Autodesk Maya</p>
          <p>Unity - Game Engine</p>
          <p>Unreal Engine</p>
          <p> <abbr title="Augmented Reality">AR</abbr> / <abbr title="Virtual Reality">VR</abbr></p>
        </div>
      </div>
    </>
  );
}
