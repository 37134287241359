// Reference - Animate box-shadow: https://stackoverflow.com/a/64828660/6908282

export default function animateShadow(IDtoAnimate, IDtoHover = document) {
    if (IDtoHover !== document) {
        IDtoHover = document.getElementById(IDtoHover)
    }
    const p = document.getElementById(IDtoAnimate);

    function clamp(a, m, n) {
        const max = Math.max(m, n);
        const min = Math.min(m, n);

        return Math.max(min, Math.min(max, a));
    }

    const MAX_SHADOW_OFFSET = 8;

    const paint = (x, y, remove = false) => {
        const r = { width: document.body.clientWidth, height: document.body.clientHeight, left: 0, right: document.body.clientWidth, top: 0, bottom: document.body.clientHeight, } // p.getBoundingClientRect();
        const o = Math.min(r.width, r.height, MAX_SHADOW_OFFSET); // compute max shadow offset

        const mx = clamp(x, r.left - o, r.right + o); // clamp mouse coordinates within the shadow projection bounding box.
        const my = clamp(y, r.top - o, r.bottom + o);
        const px = r.right - r.width / 2; // compute element bb midpoints.
        const py = r.bottom - r.height / 2;
        const nx = (mx - px) / (r.right - r.left + 2 * o); // project mouse position relative to the bounding box to [-.5, .5];
        const ny = (my - py) / (r.bottom - r.top + 2 * o);

        // setTimeout(() => {
        requestAnimationFrame(() => {
            p.style.boxShadow = remove ? "none" : `${-1 * nx * o}px ${-1 * ny * o}px 3px hsla(0, 0%, 0%, 0.2)`
            p.style.filter = remove ? "none" : `drop-shadow(${-1 * nx * o * 1.2}px ${-1 * ny * o * 1.2}px 0px hsla(0, 0%, 0%, 0.2)`
        });
        // }, 400);
    };

    IDtoHover.addEventListener('mousemove', (e) => paint(e.clientX, e.clientY), {
        passive: true
    });
    IDtoHover.addEventListener('mouseleave', (e) => paint(e.clientX, e.clientY, true), {
        passive: true
    });

    // // TODO: eventListners not working properly in mobile

    // IDtoHover.addEventListener('pointermove', (e) => paint(e.clientX, e.clientY), {
    //     passive: true
    // });

    // IDtoHover.addEventListener('pointerup', (e) => paint(e.clientX, e.clientY, true), {
    //     passive: true
    // });
}