import './legend.css';

function SkillLegend() {

    return (
        <div>
            <h1>Skills</h1>
            <div className="title-shadow"></div>
            <div className="sticky">
                <progress className="progress-legend" value="66" max="100"></progress>
                <div id="skill-rating-legend" className="distribute">
                    <p title="Rookie">Rookie</p>
                    <p title="Beginner">Beginner</p>
                    <p title="Intermediate">Intermediate</p>
                    <p title="Skilled">Skilled</p>
                    <p title="Advanced">Advanced</p>
                </div>
            </div>
            <div id="skillsLegend" className="Skill-Categories distribute">
                <p>Data Analytics & Visualization</p>
                <p>Product Management</p>
                <p>Web Development</p>
                <p>Architecture & Construction</p>
                <p>BIM</p>
                <p>Automation</p>
                <p>Computational Modeling</p>
                <p>UI & UX</p>
                <p>MS Power Platform</p>
            </div>
        </div>
    );

}

export default SkillLegend;