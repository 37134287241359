function NuDe() {

    return (
        <>
            <p>
                Nu. De was a firm that's true to its name. Exposing design to the real world in its true form, coming up with designs that blend in with the nature and yet still stand out.
                <br />
                <br />
                This is where I was able to implement and develop my computational design skills. I have used <a href="https://www.rhino3d.com/6/new/grasshopper/"> Grasshopper</a> - a Rhino 3D plugin, that lets you generate 3D geometry using computational logic, with its node based programming interface. In the 6 months I have been with them, I have improved my technical skills and learned to work with a team. I have also gained some real life experience in Architecture and Construction projects, collaborating with the on-site team and helping in getting the project executed.
                <br /> <br />
                And finally, if you're wondering about the name of the firm, It stands for "<b>Nu</b>ru Karim <b>De</b>sign".
                <br />
                {/* I really liked the bold statement the architect was giving along with a meaning to the name. And that's probably one of the reasons I decided to join them. */}
            </p>
        </>

    );
}

export default NuDe;