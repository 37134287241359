import React from 'react';
import './App.css';

import Navbar from './Components/navbar/navbar';
import ThemeBack from './Components/ThemeBack/ThemeBack';
import Home from './Components/Home/home';
import About from './Components/About/about';
import Timeline from './Components/Timeline/timeline';
import Experience from './Components/Experience/experience';
import Skills from './Components/Skills/skills';
import Projects from './Components/Projects/projects';
import Footer from './Components/Footer/footer';
import timelineJSON from "./Components/Timeline/timeline.json"

// Check Theme on initial page load before rendering: https://stackoverflow.com/a/69212916/6908282
//get stored theme right at the beginning
let currentTheme = localStorage.getItem("theme");
if (!currentTheme) currentTheme = "theme-dark"; // if no theme was stored, set to default --> dark-theme

document.body.className = currentTheme;

// TEMPORARILY DISABLE TRANSITION ON PAGE LOAD
// I'm using transition-delay to animate a ripple on themeToggle click. This doenst work well with paegload themeswitch
// So remove transition on page load so that theme background color loads instantly. 
// Then remove inline - style to enable body style from index.css from module after 300ms
// reference: https://stackoverflow.com/a/69212916/6908282
document.body.style.transition = `none`
setTimeout(() => { document.body.style.transition = `` }, 400);

function App() {
  return (
    <React.Fragment>
      {/* React.Fragment to return multiple elements: https://stackoverflow.com/a/34893582/6908282 */}
      <Navbar />
      <main>
        <ThemeBack /> {/* TODO: shouldn't this be loaded in navbar */}
        <Home />
        <About />
        <Timeline timelineData={timelineJSON} />
        <Experience />
        <Skills />
        <Projects />
        <Footer />
      </main>

    </React.Fragment>
  );
}

export default App;
