// Reference: https://blog.sethcorker.com/harnessing-the-page-visibility-api-with-react/

import React from "react";

export function getBrowserVisibilityProp() {
    if (typeof document.hidden !== "undefined") {
        // Opera 12.10 and Firefox 18 and later support
        return "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
        return "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
        return "webkitvisibilitychange";
    }
}

export function getBrowserDocumentHiddenProp() {
    if (typeof document.hidden !== "undefined") {
        return "hidden";
    } else if (typeof document.msHidden !== "undefined") {
        return "msHidden";
    } else if (typeof document.webkitHidden !== "undefined") {
        return "webkitHidden";
    }
}

export function getIsDocumentHidden() {
    return !document[getBrowserDocumentHiddenProp()];
}

export function usePageVisibility() {
    const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
    const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

    React.useEffect(() => {
        const visibilityChange = getBrowserVisibilityProp();

        document.addEventListener(visibilityChange, onVisibilityChange, false);
        // console.log("animation triggered") // this is used to check how many time the event is triggered

        return () => {
            document.removeEventListener(visibilityChange, onVisibilityChange);
        };
    }, []);
    // added empty array ("[]") above to trigger this only once - Reference: https://stackoverflow.com/a/53121021/6908282
    // Additional Reference - useEffect running more than once: https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar#:~:text=more%20than%20once%20in%20your%20page

    return isVisible;
}