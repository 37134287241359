import './navbar.css';
import { useEffect, useState } from 'react';

import Logo from '../Home/Logo/logo'
import Hamburger from './Hamburger/Hamburger'
import ThemeToggle from './ThemeToggle/themeToggle'

export default function Navbar() {

    useEffect(() => {
        // Intersection observe below for Navbar tranparency: https://www.youtube.com/watch?v=RxnV9Xcw914
        const Timeline_div = document.getElementById("logo");
        let options = {
            root: null,
            rootMargin: "-100px",
            threshold: 1.0,
        };
        let callback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    document.getElementById("topnav").style.backgroundColor = "transparent";
                    // } else if (firefox) {
                    //   document.getElementById("topnav").css("background-color", "#1A1F26");
                } else {
                    document.getElementById("topnav").style.backgroundColor = "var(--nav-background)";
                }
            });
        };

        let observer = new IntersectionObserver(callback, options);
        observer.observe(Timeline_div);
    });

    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };

    return (
        <nav id="topnav" style={{ "backgroundColor": "transparent" }} role="navigation">
            <Hamburger myClick={handleToggle} class={`menu ${isActive ? "" : "opened"}`} />
            <a href="#home" title="Home">
                <Logo id="smallLogo" />
            </a>
            <div id="navLinks" className={`topnav-links ${isActive ? "" : "opened"}`}>
                <a className="active" href="#home" title="Home">Home</a>
                <a href="#about-me" title="About Me">About Me</a>
                <a href="#Experience" title="Experience">Experience</a>
                <a href="#Skills" title="Skills">Skills</a>
                <a href="#Projects" title="Projects">Projects</a>
                <a href="#social-media" title="Social Media">Contact</a>
            </div>
            <ThemeToggle />
        </nav>
    );
}
