import './timeline.css';
import { useEffect } from 'react';

export default function Timeline(props) {

  function TimelineElements(props) {
    // Correct usage of Lists & Keys: https://reactjs.org/docs/lists-and-keys.html#extracting-components-with-keys
    const entry = props.entry
    return (
      <div style={{ "right": "0px" }}>
        <div>
          <p className={entry.category}><b>{entry.title}</b></p>
          <p>{entry.name}</p>
          <p>{entry.duration}</p>
        </div>
        <div className="v-bar-offset">
          <div className={`v-bar ${entry.type}`}></div>
        </div>
      </div>
    )
  }

  let timeline = props.timelineData.map((entry, index) =>
    <TimelineElements key={index} entry={entry} />
  )

  useEffect(() => {

    let move = 0;
    var offset = 0;
    const animate_timeline = function () {
      let timelineElements = document.getElementById("timeline-elements").childNodes;
      // Nodelist reverse: https://stackoverflow.com/a/68064201/6908282
      timelineElements = [...timelineElements]
      // timelineElements = timelineElements.reverse()
      if (move === 0) {
        timelineElements.forEach(
          function (currentValue, currentIndex, listObj) {
            currentValue.style.right = offset + "px";
            offset += 20;
          });
        offset = 0;
        move = 1;
      } else {
        timelineElements.forEach(
          function (currentValue, currentIndex, listObj) {
            currentValue.style.right = "0px";
          });
        move = 0;
      }
    };

    const timelineDiv = document.getElementById("parent-timeline");
    timelineDiv.onmouseenter = animate_timeline;
    timelineDiv.onmouseleave = animate_timeline;
    // timelineDiv.onmouseup = animate_timeline;

  });
  return (
    <section id="timeline" className="center page">
      <h1>Timeline</h1>
      <div className="timeline-legend">
        <div className="distribute">
          <i className="fas fa-graduation-cap education"></i>
          <p className="academic"><b>Education</b></p>
        </div>
        <div className="distribute">
          <i className="fas fa-briefcase experience"></i>
          <p><b>Experience</b></p>
        </div>
      </div>
      <div className="title-shadow"></div>

      <div id="parent-timeline">
        <div id="timeline-bar">
          <p>🞀 Past</p>
          <p>Present 🞂</p>
        </div>
        <div id="timeline-elements">
          {timeline}
        </div>
      </div>
    </section>
  );
}
