import './footer.css';

// Reference: https://reactjs.org/docs/lists-and-keys.html#keys-must-only-be-unique-among-siblings
function Footer() {
    let socialMediaList = [
        { URL: "mailto:gangulaanwesh@gmail.com", title: "Gmail: Anwesh Gangula", faTag: "fas fa-envelope", color: "#dd4b39" },
        { URL: "https://www.linkedin.com/in/AnweshGangula", title: "Linkedin: Anwesh Gangula", faTag: "fab fa-linkedin", color: "#0077B5" },
        // { URL: "https://www.youtube.com/channel/UCorhgcAEDYCP6ShOXYX4Kew", title: "YouTube Channel: Guru-G", faTag: "fab fa-youtube", color: "#bb0000" },
        // { URL: "http://www.guru--g.blogspot.com", title: "Blog: Guru-G", faTag: "fab fa-blogger", color: "#fb8f3d" },
        // { URL: "https://stackoverflow.com/users/story/6908282", title: "Stack Overflow: Gangula", faTag: "fab fa-stack-overflow", color: "#ED7C24" },
        { URL: "https://twitter.com/AnweshGangula", title: "Twitter: Anwesh Gangula", faTag: "fab fa-twitter", color: "#1A91DA" },
        { URL: "https://github.com/AnweshGangula", title: "GitHub: Anwesh Gangula", faTag: "fab fa-github", color: "#161B22" }
    ]

    let socialMedia = socialMediaList.map((link, index) =>
        <span className="ag fa-stack fa-lg" key={index}>
            <i className="fas fa-circle fa-stack-2x" style={{ "color": `${link.color}` }}></i>
            <a href={link.URL} title={link.title}>
                <i className={link.faTag + " fa-stack-1x fa-inverse"}></i>
            </a>
        </span>
    );
    let year = new Date().getFullYear();
    return (
        <footer>
            <div id="social-media">
                <h2>Social Medial Links</h2>
                {socialMedia}
            </div>
            <div>
                <p style={{ "margin": "1em 0 0", "color": "var(--dark-text-clr)", "fontWeight": "bold" }}>
                    Made with <i className="fa fa-heart" aria-hidden="true"></i> by Anwesh Gangula
                </p>
            </div>
            {/* <p><small>This website is an attempt to re-create the <a href="https://xd.adobe.com/view/7fb36178-5dea-474c-9a4b-11c1ba8021e0-2ae0/" target="_blank" rel="noreferrer"> following design</a>.</small></p> */}
            <small id="copyright">Copyright &copy; 2020-<span id="copyrightYear">{year}</span>, Anwesh Gangula. All Rights Reserved</small>
        </footer>
    )
}

export default Footer;